import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesListRequest } from '../redux/actions/companies';
import { makeSelectFavoriteCompanyIds } from '../redux/selectors/user';
import { customFiltersSelector } from '../redux/selectors/customFilters';
import { companiesSelector } from '../redux/selectors/companies';
import useEncryptedRouter from './useEncryptedRouter';

const useCompanies = ({ defaultQuery = {}, network }) => {
  const [page, setPage] = useState(1);
  const data = useSelector(companiesSelector);
  const favoriteCompanyIds = useSelector(makeSelectFavoriteCompanyIds);
  const customFilters = useSelector(customFiltersSelector);

  const { found: companies, total, filters, initialized, isLoading, isLazyLoading } = data;
  const dispatch = useDispatch();
  const router = useEncryptedRouter();
  const currentQuery = router.query;
  const query = useMemo(
    () => ({
      ...currentQuery,
      ...defaultQuery,
    }),
    [currentQuery, defaultQuery],
  );
  useEffect(() => {
    if (!initialized && !isLoading && customFilters) {
      dispatch(
        companiesListRequest({
          network,
          query: { ...query, page: 1 },
        }),
      );
    }
  }, [network, dispatch, isLoading, initialized, query, customFilters]);

  const nextPage = () => {
    dispatch(
      dispatch(
        companiesListRequest({
          network,
          query: { ...query, page: page + 1 },
        }),
      ),
    );

    setPage(page + 1);
  };

  return { favoriteCompanyIds, companies, page, total, filters, initialized, isLoading, isLazyLoading, nextPage };
};

export default useCompanies;
