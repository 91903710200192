import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { ListFilter } from '../../molecules/listFilter';
import useFilters from '../../../hooks/useFilters';
import useRefreshDataOnQueryChange from '../../../hooks/useRefreshDataOnQueryChange';
import { companiesListRequest } from '../../../redux/actions/companies';
import { allCompanySelector } from '../../../redux/selectors/allCompany';
import { NetworkSchema } from '../../../schema/network';
import { filterTagsSelector } from '../../../helpers/filterTags';
import { companiesSelector } from '../../../redux/selectors/companies';
import { showJobAlertsSelector } from '../../../redux/selectors/network';
const JobAlert = dynamic(() => import('../jobAlertOnFilter'), { ssr: false });

export const CompanyListFilters = ({
  network,
  defaultQuery,
  filterOptions,
  isLoading,
  searchPlaceholder,
  ...rebassProps
}) => {
  const router = useRouter();
  const { jobBoardFilters } = network;
  const { locationFilter } = jobBoardFilters;
  const hideLocationFilter = !locationFilter;
  const { list: allCompanies } = useSelector(allCompanySelector);
  const { query, filterBySearchTerm, updateFilters, resetFilters } = useFilters(defaultQuery, network);
  useRefreshDataOnQueryChange({ action: companiesListRequest, network });
  const { total } = useSelector(companiesSelector);
  const showJobAlerts = showJobAlertsSelector(network);

  const tags = useMemo(() => {
    const currentQuery = { ...query, ...defaultQuery };
    return filterTagsSelector({
      allCompanies,
      query: currentQuery,
    });
  }, [allCompanies, query, defaultQuery]);

  return (
    <ListFilter
      network={network}
      isLoading={isLoading}
      searchPlaceholder={searchPlaceholder}
      tags={tags}
      onRemoveTag={({ tag, queryParam }) => {
        const filter = { queryParam, value: tag.id, name: tag.name };
        updateFilters({ filter, mode: 'remove' });
      }}
      searchValue={router.query.q}
      updateFilters={updateFilters}
      origin="companies"
      hideLocationFilter={hideLocationFilter}
      itemCount={total || 0}
      filterBySearchTerm={filterBySearchTerm}
      resetFilters={resetFilters}
      rightItem={<JobAlert network={network} />}
      filterOptions={filterOptions}
      showJobAlert={showJobAlerts}
      sx={{
        mb: '40px',
      }}
      {...rebassProps}
    />
  );
};

CompanyListFilters.propTypes = {
  isLoading: PropTypes.bool,
  searchPlaceholder: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  defaultQuery: PropTypes.object,
  network: NetworkSchema.isRequired,
};

CompanyListFilters.defaultProps = {
  isLoading: false,
  defaultQuery: {},
};
