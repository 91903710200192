import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'rebass/styled-components';
import { END } from 'redux-saga';
import PageLayout from '../../components/layouts/Main/PageLayout';
import SEO from '../../components/molecules/Seo';
import { ResultsList } from '../../components/molecules/resultsList';
import SearchResultsEmpty from '../../components/atoms/SearchResultsEmpty';
import { wrapper } from '../../redux/store';
import {
  companiesListRequest,
  loadDiscardCompany,
  loadFavoriteCompany,
  moreCompaniesRequest,
} from '../../redux/actions/companies';
import { CompanyListItem } from '../../components/molecules/companyListItem';
import useCompanies from '../../hooks/useCompanies';
import { CompanyListFilters } from '../../components/organisms/companyListFilters';
import { isAuthenticatedSelector, makeSelectUserVerified } from '../../redux/selectors/user';
import { getSSRProtocolAndHost } from '../../helpers/ssrHelpers';
import { toggleSignUpModal } from '../../redux/actions/userActions';
import { capitalize } from '../../helpers/stringHelper';
import useCustomFilters from '../../hooks/useCustomFilters';
import { organizationsStringSelector, talentNetworkSelector } from '../../redux/selectors/network';
import { NetworkSchema } from '../../schema/network';
import { ProtocolSchema } from '../../schema/protocol';
import { decryptQuery } from '../../hooks/useEncryptedRouter';
import useJobFunctions from '../../hooks/useJobFunctions';
import { useCompanyFacets } from '../../hooks/useCompanyFacets';

const Index = ({ network, host, protocol }) => {
  const { visibleCustomFilters, isLoading: isLoadingCustomFilters } = useCustomFilters({
    network,
    useOrganizationPrefix: false,
  });

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { favoriteCompanyIds, companies, total, isLoading, initialized, isLazyLoading, nextPage } = useCompanies({
    network,
  });
  const { jobFunctions, isLoadingJobFunctions } = useJobFunctions({ network });
  const isUserVerified = useSelector(makeSelectUserVerified({ network }));
  const organizationsString = organizationsStringSelector(true, network);
  const organizationString = organizationsStringSelector(false, network);
  const hasTalentNetwork = talentNetworkSelector(network);
  const { jobBoardFilters } = network;
  const { stageFilter, industryTagsFilter, companySizeFilter } = jobBoardFilters;

  const hideStageFilter = !stageFilter;
  const hideIndustryTagsFilter = !industryTagsFilter;
  const hideCompanySizeFilter = !companySizeFilter;
  const capitalizedOrganizationsString = capitalize(organizationsString);
  const {
    industryTags,
    headCounts,
    stages,
    isLoading: isLoadingFacets,
  } = useCompanyFacets({ network, origin: 'companies' });

  const isFavorite = (company) => favoriteCompanyIds.indexOf(company.id) > -1;

  const filterOptions = useMemo(() => {
    let order = 1;
    const result = [];

    if (industryTags?.length && !hideIndustryTagsFilter) {
      order += 1;
      result.push({
        order,
        queryParam: 'industry_tags',
        name: 'Industry',
        options: industryTags,
        canFilterResults: industryTags.length > 10,
      });
    }

    if (stages?.length && !hideStageFilter) {
      order += 1;

      result.push({
        order,
        queryParam: 'stage',
        name: 'Stage',
        options: stages,
        sort: false,
        canFilterResults: stages.length > 10,
      });
    }

    if (headCounts?.length && !hideCompanySizeFilter) {
      order += 1;
      result.push({
        order,
        queryParam: 'head_count',
        name: 'Size',
        options: headCounts,
        sort: false,
        canFilterResults: headCounts.length > 10,
      });
    }

    if (visibleCustomFilters?.length) {
      result.push(
        ...visibleCustomFilters.map((item) => {
          order += 1;

          return {
            ...item,
            order,
            sort: false,
            canFilterResults: item?.options?.length > 10,
          };
        }),
      );
    }

    return result;
  }, [
    visibleCustomFilters,
    headCounts,
    hideCompanySizeFilter,
    hideIndustryTagsFilter,
    hideStageFilter,
    industryTags,
    jobFunctions,
    stages,
  ]);

  return (
    <Flex flexDirection="column">
      <SEO network={network} title={capitalizedOrganizationsString} host={host} protocol={protocol} />
      <CompanyListFilters
        isLoading={isLoadingCustomFilters || isLoadingJobFunctions || isLoadingFacets}
        network={network}
        filterOptions={filterOptions}
        searchPlaceholder="Search by name or keyword"
      />
      <ResultsList
        isLoading={isLoading}
        isInitialized={initialized}
        isLazyLoading={isLazyLoading}
        canLoadMore={companies.length < total && !isLazyLoading}
        onLoadMore={() => {
          dispatch(moreCompaniesRequest({ network }));
          nextPage();
        }}
        items={companies}
        pb={4}
        listType={[organizationString, organizationsString]}
        total={total}
        noResults={
          <SearchResultsEmpty
            content={`There are no ${organizationsString} with this criteria, try changing your filters.`}
          />
        }
        network={network}
      >
        <Flex mx={-2} flexWrap="wrap" justifyContent="flex-start" alignItems="stretch" flexDirection="columns">
          {companies.map((company) => (
            <Box display="flex" alignItems="stretch" width={[1, 1 / 2, 1 / 3]} p={2} key={company.id}>
              <CompanyListItem
                network={network}
                organizationString={organizationString}
                canDiscard={hasTalentNetwork}
                canFavorite={hasTalentNetwork}
                isFavorite={isFavorite(company)}
                isDiscarded={company.isDiscarded}
                showDiscardedOverlay
                onDiscard={() => {
                  if (isAuthenticated) {
                    dispatch(loadDiscardCompany({ network, company, discarded: !company.isDiscarded }));
                  } else {
                    dispatch(
                      toggleSignUpModal({
                        button: 'discard',
                        type: 'organization',
                        id: company.id,
                      }),
                    );
                  }
                }}
                onFavorite={() => {
                  if (isAuthenticated) {
                    dispatch(loadFavoriteCompany({ network, company, favorite: !isFavorite(company) }));
                  } else {
                    dispatch(toggleSignUpModal({ network, button: 'like', type: 'organization', id: company.id }));
                  }
                }}
                company={company}
                isTalentNetworkEnabled={hasTalentNetwork}
                isUserVerified={isUserVerified}
              />
            </Box>
          ))}
        </Flex>
      </ResultsList>
    </Flex>
  );
};

Index.Layout = PageLayout;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { protocol, host } = getSSRProtocolAndHost(ctx);
  const baseQuery = ctx.query;
  const query = decryptQuery(baseQuery);
  const { req } = ctx;
  const { network } = req;

  if (network?.id) {
    store.dispatch(
      companiesListRequest({
        network,
        query: { ...query, page: 1 },
      }),
    );
  }

  store.dispatch(END);
  await store.sagaTask.toPromise();

  return {
    props: { protocol, host },
  };
});

Index.propTypes = {
  protocol: ProtocolSchema.isRequired,
  host: PropTypes.string.isRequired,
  network: NetworkSchema.isRequired,
};

export default Index;
